<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              v-bind:class="{
                'align-items-center justify-content-end': isActive,
                'd-flex align-items-center justify-content-end': !isActive,
              }"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="criteria"
                  :placeholder="$t('message.bookmark.search')"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <br v-if="isActive" />
              <b-dropdown
                right
                variant="gradient-warning"
                class="mr-1"
                v-if="restrictions('button_export_data')"
              >
                <template slot="button-content">
                  <feather-icon icon="DownloadIcon" />
                </template>
                <b-dropdown-item
                  ><download-excel
                    :data="jobList"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.allData')"
                  >
                    {{ $t("message.buttons.allData") }}
                  </download-excel></b-dropdown-item
                >
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="my-table"
        ref="refjobListTable"
        class="position-relative"
        :items="jobList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isBusy"
        show-empty
        @row-clicked="openViewPage"
        hover
        :filter="criteria"
      >
        <template #table-busy>
          <div class="text-center my-17">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }}... </strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: Description -->
        <template #cell(description)="data">
          {{ data.item.DESCRIPTION }}
        </template>

        <!-- Column: Cron -->
        <template #cell(cron)="data">
          {{ data.item.CRON }}
        </template>

        <!-- Column: Duaration -->
        <template #cell(duration)="data">
          {{ data.item.DURATION }}
        </template>

        <!-- Column: Last Execution -->
        <template #cell(execution)="data">
          <b-badge
            pill
            :variant="`light-${executionVariant(data.item.LAST_RESULT)}`"
            class="text-capitalize"
          >
            <span class="align-text-top text-capitalize">{{
              data.item.LAST_RESULT
            }}</span>
          </b-badge>
        </template>

        <!-- Column: Block -->
        <template #cell(block)="data">
          {{ data.item.BLOCK }}
        </template>

        <!-- Column: Active -->
        <template #cell(active)="data">
          <b-form-checkbox
            :checked="data.item.ACTIVE"
            class="custom-control-success"
            name="check-button"
            switch
            @input="updateJob(data.item.ID)"
          >
          </b-form-checkbox>
        </template>

        <!-- Column: Last Execution -->
        <template #cell(lastExecution)="data">
          {{ localeDate(data.item.LAST_EXECUTION) }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("message.pagelength") }} {{ dataMeta.from }}
              {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
              {{ totalRegisters }} {{ $t("register") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="showPagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import usejobsList from "./useJobsList";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import Ripple from "vue-ripple-directive";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import store from "@/store";

Vue.component("downloadExcel", JsonExcel);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
    BFormCheckbox,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      jobList: [],
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,

      criteria: "",

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        {
          key: "description",
          sortable: false,
          label: this.getColLabel("description"),
        },
        { key: "cron", sortable: false, label: this.getColLabel("cron") },
        {
          key: "duration",
          sortable: false,
          label: this.getColLabel("duration"),
        },

        {
          key: "lastExecution",
          sortable: false,
          label: this.getColLabel("lastExecution"),
        },
        { key: "block", sortable: false, label: this.getColLabel("block") },
        {
          key: "execution",
          sortable: false,
          label: this.getColLabel("execution"),
        },
        { key: "active", sortable: false, label: this.getColLabel("active") },
        {
          key: "lastExecution",
          sortable: false,
          label: this.getColLabel("lastExecution"),
        },
      ];
    },
  },

  async created() {
    this.onResize();
    this.getJobList();
  },

  mounted() {
    this.$root.$on("newListJob", (newListJob) => {
      this.jobList = newListJob;
      this.totalRegisters = newListJob.totalElements;
      this.perPage = newListJob.numberOfElements;
      this.currentPage = 1;
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    keysToLowerCase(obj) {
      Object.keys(obj).forEach(function (key) {
        var k = key.toLowerCase();

        if (k !== key) {
          obj[k] = obj[key];
          delete obj[key];
        }
      });
      return obj;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.isActive = ListWidth(this.windowWidth);
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async updateJob(id) {
      var list = [];

      for (var i = 0; i < this.jobList.length; i++) {
        if (this.jobList[i].ID === id) {
          list.push(this.jobList[i]);
        }
      }

      var lowerCase = [];
      lowerCase = this.keysToLowerCase(list[0]);

      if (lowerCase.active === "true") {
        lowerCase.active = "false";
      } else {
        lowerCase.active = "true";
      }

      await axios({
        method: "put",
        url: `${URL_API}job/update/${lowerCase.id}`,
        headers: getHeader(this.userData),
        data: lowerCase,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });

      this.getJobList();
    },

    restrictions(value) {
      return AccessControl(value);
    },

    executionVariant(status) {
      if (status === "COMPLETED") return "success";
      return "danger";
    },

    getColLabel(colName) {
      return this.$i18n.t("message.job." + colName);
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({ name: "apps-jobs-view", params: { id: row.ID } });
    },

    startDownload() {
      this.isBusy = true;
    },
    finishDownload() {
      this.isBusy = false;
    },

    async getJobList() {
      var list = [];

      this.isBusy = true;
      await axios
        .get(`${URL_API}job/list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (var i = 0; i < response.data.body.length; i++) {
            if (
              response.data.body[i].ID != null ||
              response.data.body[i].ID != undefined
            ) {
              list.push(response.data.body[i]);
            }
          }

          this.totalRegisters = list.length;
          this.jobList = list;
          this.perPage = list.length;
          this.isBusy = false;
        });

      if (this.jobList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },
  },

  setup() {
    const {
      perPage,
      currentPage,
      totaljobs,
      dataMeta,
      searchQuery,
      refjobListTable,
    } = usejobsList();

    return {
      perPage,
      currentPage,
      totaljobs,
      dataMeta,
      searchQuery,
      refjobListTable,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
